import React, { forwardRef, ReactNode } from 'react'
import styled, { css, SimpleInterpolation } from 'styled-components'
import { mediaQueries } from '../../../utils/mediaQueries'
import { rem } from '../../../utils/rem'

export enum TitleSizes {
  large = 'large',
  medium = 'medium',
  small = 'small',
  xLarge = 'xLarge',
  xSmall = 'xSmall',
  xxLarge = 'xxLarge',
}

export interface TitleProps {
  children: ReactNode
  className?: string
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  responsiveSizes?: {
    desktop?: TitleSizes
    tablet?: TitleSizes
    ultrawide?: TitleSizes
  }
  size?: TitleSizes
}

const Title = forwardRef<HTMLHeadingElement, TitleProps>(
  (
    {
      children,
      className,
      size = TitleSizes.xLarge,
      responsiveSizes,
      level = 'h1',
      ...props
    },
    ref,
  ) => (
    <StyledTitle
      as={level}
      className={className}
      ref={ref}
      responsiveSizes={responsiveSizes}
      size={size}
      {...props}
    >
      {children}
    </StyledTitle>
  ),
)

Title.displayName = 'Title'

const getSpecificStyles = (
  size: TitleSizes,
  responsiveSizes?: {
    desktop?: TitleSizes
    tablet?: TitleSizes
    ultrawide?: TitleSizes
  },
): SimpleInterpolation | null => css`
  ${getSizeStyles(size)}

  ${responsiveSizes &&
  responsiveSizes.tablet &&
  css`
    ${mediaQueries.tablet} {
      ${getSizeStyles(responsiveSizes.tablet)};
    }
  `};

  ${responsiveSizes &&
  responsiveSizes.desktop &&
  css`
    ${mediaQueries.desktop} {
      ${getSizeStyles(responsiveSizes.desktop)};
    }
  `};

  ${responsiveSizes &&
  responsiveSizes.ultrawide &&
  css`
    ${mediaQueries.ultrawide} {
      ${getSizeStyles(responsiveSizes.ultrawide)};
    }
  `};
`

const getSizeStyles = (size: TitleSizes): SimpleInterpolation | null => {
  switch (size) {
    case TitleSizes.xSmall:
      return css`
        font-size: ${rem(15)};
        line-height: ${rem(23)};
        letter-spacing: -0.02em;
        font-weight: 700;
      `
    case TitleSizes.small:
      return css`
        font-size: ${rem(20)};
        line-height: ${rem(26)};
      `
    case TitleSizes.medium:
      return css`
        font-size: ${rem(22)};
        line-height: ${rem(32)};
      `
    case TitleSizes.large:
      return css`
        font-size: ${rem(28)};
        line-height: ${rem(32)};
      `
    case TitleSizes.xLarge:
      return css`
        font-size: ${rem(35)};
        line-height: ${rem(40)};
        letter-spacing: -0.03em;
      `
    case TitleSizes.xxLarge:
      return css`
        font-size: ${rem(60)};
        line-height: ${rem(75)};
      `
  }

  return null
}

interface StyledTitleProps {
  as?: keyof JSX.IntrinsicElements
  responsiveSizes?: {
    desktop?: TitleSizes
    tablet?: TitleSizes
    ultrawide?: TitleSizes
  }
  size: TitleSizes
}

const StyledTitle = styled.h1<StyledTitleProps>`
  ${({ size, responsiveSizes }): SimpleInterpolation | null =>
    getSpecificStyles(size, responsiveSizes)};
`

export default Title
