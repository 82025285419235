import { useContext } from 'react'
import ImagesContext, {
  ImageNodeData,
} from '../../contexts/images/ImagesContext'

/**
 * Hook for single image
 * @param fileName
 */
const useImageData = (fileName?: string): ImageNodeData | null => {
  const imagesContext = useContext(ImagesContext)

  if (typeof fileName === 'undefined') return null

  return fileName ? (imagesContext.getImage(fileName) as ImageNodeData) : null
}

/**
 * Similar hook for multiple images
 * @param fileNames
 */
export const useImagesData = (fileNames: string[]): ImageNodeData[] => {
  const imagesContext = useContext(ImagesContext)

  return fileNames ? (imagesContext.getImage(fileNames) as ImageNodeData[]) : []
}

export default useImageData
